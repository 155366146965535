import React, { Dispatch, SetStateAction, useState } from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
  ExerciseParams,
  GetExercisesResponse,
} from "@/pages/Exercises/ExercisesV3/exercisesV3.types";
import client from "@/api/client";
import useDebounce from "@/hooks/useDebounce";
import useScreen from "@/hooks/useScreen";

const ExerciseContext = React.createContext<ExerciseContextType | undefined>(
  undefined
);

type ExerciseContextType = {
  toolBarEditState: {
    isEditing: boolean;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
  };
  queryResult: UseQueryResult<GetExercisesResponse, unknown> | undefined;
  paramState: {
    queryParams: ExerciseParams;
    setQueryParams: Dispatch<SetStateAction<ExerciseParams>>;
  };
  addNewExerciseOpenState: {
    addNewExerciseOpen: boolean;
    setAddNewExerciseOpen: Dispatch<SetStateAction<boolean>>;
  };
  assignOldExerciseState: {
    assignOldExerciseOpen: boolean;
    setAssignOldExerciseOpen: Dispatch<SetStateAction<boolean>>;
  };
  queryKey: any;
};

export const ExerciseContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { perPage } = useScreen();

  const [queryParams, setQueryParams] = useState<ExerciseParams>({
    filters: { search: "", page: 1 },
    perPage: 12,
  });

  const [addNewExerciseOpen, setAddNewExerciseOpen] = useState(false);
  const [assignOldExerciseOpen, setAssignOldExerciseOpen] = useState(false);

  const QUERY_KEY = [
    "exercises-v3",
    useDebounce(queryParams.filters["search"], 800),
    queryParams.filters["page"],
    perPage,
  ];
  const tableDataQuery = useQuery<GetExercisesResponse>(
    QUERY_KEY,
    () => client.exercises.getExercises({ ...queryParams }),
    {
      enabled: perPage > 0,
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );

  const contextValue: ExerciseContextType = {
    toolBarEditState: { isEditing, setIsEditing },
    queryResult: tableDataQuery,
    paramState: { queryParams, setQueryParams },
    addNewExerciseOpenState: { addNewExerciseOpen, setAddNewExerciseOpen },
    assignOldExerciseState: { assignOldExerciseOpen, setAssignOldExerciseOpen },
    queryKey: QUERY_KEY,
  };

  return (
    <ExerciseContext.Provider value={contextValue}>
      {children}
    </ExerciseContext.Provider>
  );
};

export const useExerciseContext = (): ExerciseContextType => {
  const context = React.useContext(ExerciseContext);

  if (!context) {
    throw new Error(
      "useExerciseContext must be used within an ExerciseContextProvider"
    );
  }

  return context;
};

export default ExerciseContext;
