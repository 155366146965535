import flags from "./flags";
import classNames from "classnames";

type Props = {
  flag: string;
  className?: string;
};

const FlagRender = (props: Props) => {
  const { flag, className, ...rest } = props;

  return (
    <img
      alt={flag}
      src={flags[flag] ?? flags["it"]}
      className={classNames({ "p-3": !className }, className)}
      {...rest}
    />
  );
};

export default FlagRender;
