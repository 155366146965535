import { ExerciseModal } from "./ExerciseModal";
import { useExerciseContext } from "@/pages/Exercises/ExercisesV3/state/ExerciseContext";
import { Box, Button, TextField, useTheme } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import addIcon from "@/assets/icons/add.svg";
import backIcon from "@/assets/icons/back.svg";
import searchIcon from "@/assets/icons/search.svg";
import materialIcon from "@/assets/icons/material.svg";
import AddExerciseContent from "@/pages/Exercises/ExercisesV3/components/AddExerciseContent";
import AssignOldExerciseContent from "./AssignOldExerciseContent";

interface ToolBarProps {
  playerRef: React.RefObject<any>;
  watchImgRef: React.RefObject<any>;
}

export default ({ watchImgRef, playerRef }: ToolBarProps) => {
  const theme = useTheme();
  const {
    paramState,
    // toolBarEditState,
    assignOldExerciseState,
    addNewExerciseOpenState,
  } = useExerciseContext();

  // const { isEditing, setIsEditing } = toolBarEditState;
  const { queryParams, setQueryParams } = paramState;
  const { addNewExerciseOpen, setAddNewExerciseOpen } = addNewExerciseOpenState;
  const { assignOldExerciseOpen, setAssignOldExerciseOpen } =
    assignOldExerciseState;

  return (
    <Box className="flex gap-4 itels-stretch">
      <Button
        title="Add new exercises"
        variant="outlined"
        onClick={() => {
          setAddNewExerciseOpen(true);
        }}
      >
        <img
          src={addIcon}
          className="icon"
        />
        Add
      </Button>

      {/* {isEditing && (
        <>
          <Button
            variant="outlined"
            onClick={() => setIsEditing(false)}
          >
            <img
              src={backIcon}
              className="icon"
              alt="Back icon"
            />
            Back
          </Button>

          {["remove", "reset", "add"].map((action, i) => (
            <Button
              key={i}
              variant="contained"
            >
              {i === 1 ? "Reset" : i === 2 ? "+ Add" : "- Remove"}
            </Button>
          ))}
        </>
      )} */}

      {/* {!isEditing && (
        <Button
          variant="outlined"
          onClick={() => setIsEditing(true)}
        >
          <img
            src={materialIcon}
            className="icon"
          />
          Edit New Exercises
        </Button>
      )} */}

      <Button
        variant="outlined"
        onClick={() => {
          setAssignOldExerciseOpen(true);
        }}
      >
        <img
          src={addIcon}
          className="icon"
        />
        Assign
      </Button>

      <TextField
        sx={{ marginLeft: "auto", maxWidth: "200px" }}
        InputProps={{
          startAdornment: (
            <img
              src={searchIcon}
              style={{ marginRight: 10, filter: theme.palette.filter }}
            />
          ),
        }}
        name="search"
        size="small"
        variant="outlined"
        value={queryParams.filters["search"] || ""}
        onChange={(e) =>
          setQueryParams((prevQueryParams) => ({
            ...prevQueryParams,
            filters: { search: e.target.value },
          }))
        }
        placeholder="Search..."
      />

      {addNewExerciseOpen && (
        <ExerciseModal
          open={addNewExerciseOpen}
          icon={
            <img
              src={addIcon}
              alt="Add icon"
            />
          }
          title="Add New Ielts Exercise"
          onClose={() => setAddNewExerciseOpen(false)}
        >
          <AddExerciseContent
            playerRef={playerRef}
            watchImgRef={watchImgRef}
          />
        </ExerciseModal>
      )}

      {Boolean(assignOldExerciseOpen) && (
        <EduModal
          fullWidth
          open={Boolean(assignOldExerciseOpen)}
          maxWidth="md"
        >
          <EduModalTitle onClose={() => setAssignOldExerciseOpen(false)}>
            Exercises Assignment
          </EduModalTitle>

          <EduModalBody className="p-5 pt-7">
            <AssignOldExerciseContent />
          </EduModalBody>
        </EduModal>
      )}
    </Box>
  );
};
