import { Delete } from "@mui/icons-material";
import { IExercise } from "./interfacesAndDefaults";
import { Box, IconButton } from "@mui/material";
import InputTextSelect from "@/pages/Exercises/ExercisesV1/components/InputTextSelect";

export default ({
  index,
  question,
  exercise,
  keyVersion,
  setExercise,
  indexQuestion,
}) => {
  return (
    <Box className={"w-full flex gap-2 items-center p-1 py-3 hover:bg-slate-600/5 rounded " + (index ? "mt-4" : "")}>
      <Box className="w-[2rem] text-2xl text-center">{index + 1}</Box>

      <Box className="grow flex flex-col gap-4">
        <InputTextSelect
          required
          type="text"
          label="Statement"
          placeholder="Ex: Question 1"
          value={question.statement || ""}
          setValue={(value) => {
            setExercise((e: IExercise) => {
              const t = { ...e };
              t.exercises[keyVersion][indexQuestion].statement = value;
              return t;
            });
          }}
        />

        <InputTextSelect
          required
          type="text"
          label="Correct answer"
          placeholder="Ex: words"
          value={question.correct_answer || ""}
          setValue={(value) => {
            setExercise((e: IExercise) => {
              const t = { ...e };
              t.exercises[keyVersion][indexQuestion].correct_answer = value;
              return t;
            });
          }}
        />
      </Box>

      <IconButton
        title="Delete option"
        onClick={() => {
          setExercise((e: IExercise) => {
            const t = { ...e };
            t.exercises[keyVersion] = t.exercises[keyVersion].filter(
              (_: any, i: number) => i != indexQuestion
            );
            return t;
          });
        }}
        disabled={exercise.exercises[keyVersion].length <= 1}
      >
        <Delete />
      </IconButton>
    </Box>
  );
}
