import { Dispatch, SetStateAction } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { IDefaultOpenEditDialog } from "./ExerciseTable";
import { MoreVert, PictureAsPdf } from "@mui/icons-material";
import { MenuList, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import dayjs from "dayjs";
import editIcon from "@/assets/icons/edit.svg";
import Dropdown from "@/components/_Common/Dropdown";
import MyTooltip from "@/components/MyTooltip";
import deleteIcon from "@/assets/icons/delete.svg";

interface IProps {
  setDeleteRow: Dispatch<SetStateAction<number | null>>;
  setDialogFile: Dispatch<SetStateAction<any>>;
  handleOpenEdit: (row: any) => void;
  setIeltsExercise: Dispatch<SetStateAction<any>>;
  setOpenEditDialog: Dispatch<SetStateAction<IDefaultOpenEditDialog>>;
  setOpenDeleteDialog: Dispatch<SetStateAction<boolean>>;
}

export const ExerciseTableColumns = ({
  setDeleteRow,
  setDialogFile,
  handleOpenEdit,
  setIeltsExercise,
  setOpenEditDialog,
  setOpenDeleteDialog,
}: IProps): GridColDef[] => [
  {
    field: "exercise_type",
    headerName: "Version",
    minWidth: 50,
    renderCell: ({ row }) => (
      <MyTooltip>{row.exercise_type.toUpperCase()}</MyTooltip>
    ),
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) => <MyTooltip>{row.name}</MyTooltip>,
  },
  {
    field: "modality",
    headerName: "Type",
    minWidth: 90,
    renderCell: ({ row }) =>
      row?.type && row?.type.length > 0
        ? row?.type.charAt(0).toUpperCase() + row?.type.slice(1).toLowerCase()
        : "-",
  },
  {
    field: "category",
    headerName: "Category",
    minWidth: 90,
    flex: 1,
    valueGetter: ({ row }) => {
      if (!row?.category) return "-";
      return Array.isArray(row.category)
        ? row.category.join(", ")
        : row.category;
    },
    renderCell: ({ row }) => {
      const categoryValue = row?.category
        ? Array.isArray(row.category)
          ? row.category.join(", ")
          : row.category
        : "-";
      return <MyTooltip>{categoryValue}</MyTooltip>;
    },
  },
  {
    field: "is_precourse",
    headerName: "Precourse",
    width: 100,
    valueGetter: ({ row }) => (row?.is_precourse ? "YES" : "NO"),
  },
  {
    field: "duration",
    headerName: "Duration",
    width: 90,
    valueGetter: ({ row }) => row?.duration,
    valueFormatter: ({ value }) => (value ? value + " mins" : "-"),
  },
  {
    field: "lang",
    headerName: "Language",
    width: 100,
    valueGetter: ({ row }) => row?.lang,
  },
  {
    field: "created_at",
    headerName: "Created at",
    width: 130,
    valueGetter: ({ row }) =>
      dayjs(row.created_at).tz("Europe/Rome").format("ddd, DD-M HH:mm"),
  },
  {
    field: "actions",
    headerName: "",
    hideable: false,
    sortable: false,
    editable: false,
    filterable: false,
    disableExport: true,
    width: 70,
    renderCell: ({ row }) => {
      const isOld = row?.exercise_type === "old";
      const isNew = row?.exercise_type === "new";

      return (
        <Dropdown text={<MoreVert />}>
          <MenuList>
            {Boolean(row?.files?.media) && (
              <MenuItem
                title="Show media"
                color="primary"
                onClick={() => setDialogFile(row)}
              >
                <ListItemIcon>
                  <PictureAsPdf />
                </ListItemIcon>
                <ListItemText>Show media</ListItemText>
              </MenuItem>
            )}

            <MenuItem
              title="Edit"
              color="primary"
              onClick={() => {
                if (isOld) {
                  setIeltsExercise(row);
                  setOpenEditDialog({
                    open: true,
                    type: "ielts-old",
                    payload: row,
                  });
                } else if (isNew) {
                  handleOpenEdit(row);
                  setOpenEditDialog({
                    open: true,
                    type: "pot-new",
                    payload: row,
                  });
                }
              }}
            >
              <ListItemIcon>
                <img
                  src={editIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>

            <MenuItem
              title="Delete"
              color="primary"
              onClick={() => {
                if (isOld) {
                  setIeltsExercise(row);
                  setOpenDeleteDialog(true);
                } else if (isNew) {
                  setDeleteRow(row.id);
                }
              }}
            >
              <ListItemIcon>
                <img
                  src={deleteIcon}
                  className="icon no-margin"
                />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuList>
        </Dropdown>
      );
    },
  },
];
