import {
  Box,
  Select,
  Tooltip,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import classNames from "classnames";
import FlagRender from "./FlagRender";

const sortingLanguagesArray = ["it", "es", "de", "fr", "pt", "en"];

const ProductRender = ({ product, hiddenFlag = false }) => {
  return (
    <Box className="flex justify-between gap-2 w-full">
      <Box className="truncate mr-2">{product?.product_tag}</Box>

      <Box
        className={classNames("grid grid-flow-col gap-2", {
          " grid-cols-[120px_minmax(70px,_1fr)]": !hiddenFlag,
        })}
      >
        <Box className={classNames({ hidden: hiddenFlag }, "flex gap-1")}>
          {product?.detail_locales &&
            Object.keys(product?.detail_locales).length > 0 &&
            Object.keys(product?.detail_locales)
              .sort(
                (a, b) =>
                  sortingLanguagesArray.indexOf(a) -
                  sortingLanguagesArray.indexOf(b)
              )
              .map((key: string, index: number) => (
                <FlagRender
                  key={key + index}
                  flag={key}
                  className="w-[20px] h-[15px] object-cover object-center icon"
                />
              ))}
        </Box>
        <Box>€{(product?.price / 100).toFixed(2)}</Box>
      </Box>
    </Box>
  );
};

export default ({ products, isLoading, onChange, value }) => (
  <FormControl
    size="small"
    className="w-full"
  >
    <InputLabel id="product-select">Product</InputLabel>

    <Select
      id="product-select"
      name="product_tk"
      color="primary"
      label="Product"
      labelId="product-select"
      value={value || ""}
      renderValue={(value) => {
        const product = products.find(
          (product: { token: string }) => product.token === value
        );

        return (
          <ProductRender
            product={product}
            hiddenFlag={true}
          />
        );
      }}
      onChange={onChange}
      endAdornment={
        <>
          {isLoading && (
            <CircularProgress
              size={15}
              className="mr-3"
            />
          )}
        </>
      }
    >
      {products.map((product: Products) => (
        <MenuItem
          key={product.id}
          value={product.token}
        >
          <Tooltip title={product?.product_tag}>
            <ProductRender product={product} />
          </Tooltip>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
