import { useMemo } from "react";
import useAuth from '@/hooks/useAuth';
import { Box, Button, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import MONTHS from "@/components/assets/MONTHS";
import addIcon from "@/assets/icons/add.svg";
import nextIcon from "@/assets/icons/next.svg";
import backIcon from "@/assets/icons/back.svg";
import Dropdown from "@/components/_Common/Dropdown";
import useUserApi from "@/api/useUserApi";
import filterIcon from "@/assets/icons/filter.svg";
import FiltersBar from "@/components/_Common/FiltersBar";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import reactivateIcon from "@/assets/icons/reactivate.svg";

export default ({
  mode,
  setMode,
  filters,
  setFilters,
  selectedMode,
  handleDownload,
  userSuspenedRef,
  setOpenConfirmReactivateDialog,
}) => {
  const { isMember } = useAuth();
  const { data: startMonthsData } = useUserApi().callGetStartMonths();

  const restartMonthMenu = useMemo(() => {
    if (!startMonthsData?.data?.length) return <></>;

    let current = dayjs(
      startMonthsData.data.at(1).month_en,
      "MMMM YYYY"
    ).startOf("month");

    const end = dayjs(
      startMonthsData.data.at(-1).month_en,
      "MMMM YYYY"
    ).startOf("month");

    const months: Array<any> = [];

    while (current <= end) {
      const m = current.format("MMMM");
      const y = current.format("YYYY");

      months.push({
        month: `${MONTHS[m]} ${y}`,
        month_en: `${m} ${y}`,
      });

      current = current.add(1, "M");
    }

    return months?.map((d) => {
      const months = d.month_en.split(" ");
      return (
        <MenuItem
          key={d.month}
          value={d.month}
        >
          <Box className="w-full flex justify-between">
            <span>{months[0]}</span>
            <span>{months[1]}</span>
          </Box>
        </MenuItem>
      );
    });
  }, [startMonthsData]);

  const activeMenu = useMemo(
    () =>
      ["Yes", "No"].map((e: string, i: number) => (
        <MenuItem
          key={e + i}
          value={e}
        >
          {e}
        </MenuItem>
      )),
    []
  );

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setFilters((p: any) => {
      if (value) p.set(name, value);
      else p.delete(name);

      return p;
    });
  };

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 !items-stretch"
    >
      <FiltersBar>
        <Button
          variant="outlined"
          onClick={() => setFilters({})}
        >
          <img
            src={refreshIcon}
            className="icon"
          />
          Refresh
        </Button>

        {!mode && (
          <>
            <Button
              variant="outlined"
              onClick={() => userSuspenedRef?.current.open()}
            >
              <img
                src={addIcon}
                className="icon"
              />
              Add manually
            </Button>

            <Button
              variant="outlined"
              onClick={() => setMode("reactivate")}
            >
              <img
                src={reactivateIcon}
                className="icon"
              />
              Reactivate
            </Button>
          </>
        )}

        <Dropdown
          text="Filter"
          icon={filterIcon}
          active={Boolean(
            Object.keys(filters).filter((key) =>
              ["restart_month", "availabilities_done", "active"].includes(key)
            ).length
          )}
        >
          <Box className="flex flex-col w-[250px] gap-5 p-5">
            <TextField
              select
              fullWidth
              name="restart_month"
              label="Restart month"
              value={filters?.restart_month || ""}
              onChange={handleChange}
            >
              {restartMonthMenu}
            </TextField>

            <TextField
              select
              fullWidth
              name="availabilities_done"
              label="Availabilities done"
              value={filters?.availabilities_done || ""}
              onChange={handleChange}
            >
              {activeMenu}
            </TextField>

            <TextField
              select
              fullWidth
              name="active"
              label="Active"
              value={filters?.active || ""}
              onChange={handleChange}
            >
              {activeMenu}
            </TextField>
          </Box>
        </Dropdown>

        {mode && (
          <Box className="flex gap-4">
            <Button
              variant="outlined"
              onClick={() => setMode("")}
            >
              <img
                src={backIcon}
                className="icon"
              />
              Back
            </Button>

            <Button
              variant="contained"
              disabled={selectedMode.length === 0}
              onClick={() => {
                if (mode === "reactivate") {
                  setOpenConfirmReactivateDialog(true);
                }
              }}
            >
              <img
                src={nextIcon}
                className="icon"
              />
              Next
            </Button>
          </Box>
        )}

        {!mode && !isMember && (
          <Button
            variant="outlined"
            onClick={handleDownload}
          >
            <img
              src={downloadIcon}
              className="icon"
            />
            Download
          </Button>
        )}
      </FiltersBar>

      <SearchToolbar
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
};
