import deleteIcon from "@/assets/icons/delete.svg";
import saveIcon from "@/assets/icons/save.svg";
import { uuid } from "@/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useState } from "react";
import client from "@/api/client";
import processErrorMessages from "@/api/client/processErrorMessages";
import useProductApi from "@/api/useProductApi";
import useAuth from '@/hooks/useAuth';

const PriceBox = ({ price, product_id, i, setProductPrices }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const labelButton = (price.product_presentation && price.product_presentation.length > 0) ? price.product_presentation?.[0].slice(0, 20) + "..." : "Choose a file";
  const { invalidateProducts } = useProductApi();
  const { snackHandler } = useAuth();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files;
    if (files) {
      setFile(files[0]);
    }
  };

  const createOrUpdatePrice = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    const formData = new FormData();

    for (const key of Object.keys(price)) {
      if (key === "id") {
        continue
      }
      formData.append(key, price[key]);
    }

    const formElements = e.currentTarget.elements;

    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i] as HTMLInputElement;

      if (element.name === "file") {
        continue;
      }

      if (formData.has(element.name)) {
        formData.delete(element.name);
      }

      formData.append(element.name, element.value);
    }

    if (file) {
      formData.delete("product_presentation");
      formData.append("file", file);
    } else {
      formData.delete("product_presentation");

      price.product_presentation.forEach((file, idx) => {
        formData.append(`product_presentation[]`, file);
      });
    }

    [...formData.keys()].forEach((key) => {
      if (formData.get(key) === null || formData.get(key) === "" || formData.get(key) === undefined) {
        formData.delete(key);
      }
    });

    try {
      if (price.id && !price.temp_id) {
        const { item, message } = await client.product.prices.update(price.id, formData, { headers: { "Content-Type": "multipart/form-data" } });

        if (item) {
          setProductPrices((prev) => {
            const newPrices = [...prev];
            const index = newPrices.findIndex((p) => p.id === price.id);
            newPrices[index] = { ...item, label: price.label };
            return newPrices;
          });
        }
        snackHandler(message, "success");
      } else {
        const { item, message } = await client.product.prices.create(product_id, formData, { headers: { "Content-Type": "multipart/form-data" } });

        if (item && price.temp_id) {
          setProductPrices((prev) => {
            const newPrices = [...prev];
            const index = newPrices.findIndex((p) => p.temp_id === price.temp_id);
            newPrices[index] = { ...item, label: price.label };
            delete newPrices[index].temp_id;
            return newPrices;
          });

          snackHandler(message, "success");
        }
      }

    } catch (error) {
      processErrorMessages(error);
    } finally {
      setIsLoading(false);
      invalidateProducts();
    }

  };

  const formId = uuid();

  return (
    <div>
      <Accordion
        component="form"
        key={i}
        id={formId}
        onSubmit={createOrUpdatePrice}
      >
        <AccordionSummary
          id={"panel1-header " + i}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
        >
          {price.label}
        </AccordionSummary>

        <AccordionDetails className="flex flex-col gap-2">
          <Box className="grid grid-cols-3 grid-flow-row md:grid-flow-col md:auto-cols-auto gap-4 items-center">
            <TextField
              required
              name="price"
              label="Price"
              placeholder="Enter the price"
              defaultValue={price.price ?? ""} // if price is null
              InputLabelProps={{ shrink: true }}
              className="col-span-1"
              //add endAdornment
              InputProps={{
                endAdornment: (
                  <>
                    {price.currency}
                  </>
                )
              }}
              inputProps={{ pattern: "^-?[0-9]*\.?[0-9]+$" }}

            />
            <TextField
              required
              name="terms"
              label="Terms"
              placeholder="https://"
              defaultValue={price.terms ?? ""}
              InputLabelProps={{ shrink: true }}
              className="col-span-1"
            />

            <label
              title={file ? file.name : labelButton}
              htmlFor={`files-${price.country}-${price.product_id}`}
              className="h-full w-full col-span-2"
            >
              <Button
                variant="outlined"
                component="span"
                className="!h-full w-full truncate"
              >
                {
                  file ? file.name : labelButton
                }
              </Button>
            </label>

            <input
              id={`files-${price.country}-${price.product_id}`}
              type="file"
              name={`file`}
              accept=".pdf"
              onChange={handleFileChange}
              className="hidden"
            />
          </Box>

          <TextField
            required
            fullWidth
            multiline
            rows={2}
            name="details"
            label="Details"
            className="!mt-2"
            placeholder="Enter the details separated by comma ,"
            defaultValue={price.details ?? ""}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            fullWidth
            name="onboarding_link"
            label="Link onboarding"
            className="!mt-2"
            defaultValue={price.onboarding_link ?? ""}
            placeholder="https://"
            InputLabelProps={{ shrink: true }}
          />

          <Box className="flex gap-2 justify-end !py-2">
            <LoadingButton
              form="main-form"
              type="button"
              color="primary"
              variant="outlined"
              loading={isDeleting}
              className="flex justify-center"
            >
              <img
                src={deleteIcon}
                className={`icon !m-0 ${isLoading ? "opacity-0" : ""}`}
              />

            </LoadingButton>
            <LoadingButton
              form={formId}
              type="submit"
              color="primary"
              variant="outlined"
              loading={isLoading}
              className="flex justify-center"
            >
              <img
                src={saveIcon}
                className={`icon !m-0 ${isLoading ? "opacity-0" : ""}`}
              />
              {/* UPDATE PRICE */}
            </LoadingButton>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PriceBox;
