import client from "@/api/client";
import useProductApi, { ICreateError } from "@/api/useProductApi";
import addIcon from "@/assets/icons/add.svg";
import editIcon from "@/assets/icons/edit.svg";
import saveIcon from "@/assets/icons/save.svg";
import ENGLISH_LEVELS from "@/components/assets/ENGLISH_LEVELS";
import langs from "@/components/assets/LANGUAGES";
import useAuth from '@/hooks/useAuth';
import { uuid } from "@/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "../../_Common/Modal/ModalStyles";
import { clone, fUpper } from "../../useHelpers";
import PriceBox from "./PriceBox";

const courseStructureDefault: CourseStructure = {
  ielts: 0,
  grammar: 0,
  speaking: 0,
  one_to_one: 0,
  linguaskill: 0,
  writing_correction: 0,
};


interface ProductPrices extends Prices {
  temp_id?: string;
  label: string;
  deleted_at?: string;
}

export default ({
  show,
  data,
  isCopy,
  setData,
  setIsCopy,
  hideDialog,
}: ProductDialogProps) => {
  const theme = useTheme();
  const { userAdmin, isAdmission, snackHandler } = useAuth();
  const { callCreateProduct, callUpdateProduct, invalidateProducts } = useProductApi();

  const payload = {
    key_value: {
      key: "code",
      value: "en",
    },
    columns: ["prefix", "currency", "locale"],
  }

  const { data: countries } = useQuery(["countries-list", JSON.stringify(payload)], () =>
    client.countries_list(payload)
  );

  const formRef = useRef<HTMLFormElement>(null);

  const [errors, setErrors] = useState<any>({});
  const [product, setProduct] = useState(data);
  const [courseMonths, setCourseMonths] = useState(3);
  const [productPrices, setProductPrices] = useState<ProductPrices[]>([]);
  const [trashedPrices, setTrashedPrices] = useState<ProductPrices[]>([]);

  const LANGUAGES = langs.filter((lang) => lang.iso !== "en");

  const {
    mutate: createProduct,
    isLoading,
    error: createProductError,
  } = callCreateProduct;

  const onClose = () => {
    hideDialog(false);
    setData(null);
    setIsCopy(false);
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    formData.set("course_info[months]", courseMonths.toString());
    formData.set(
      "course_info[course_name]",
      formData.get("product_tag") as string
    );
    formData.set("tutoring", isAdmission ? "1" : "0");

    // unset all null values
    [...formData.keys()].forEach((key) => {
      if (formData.get(key) === null || formData.get(key) == "") {
        formData.delete(key);
      }
    });

    const params = {
      token: userAdmin.token,
      formData,
      callback: () => { },
    };

    if (product?.token) {
      callUpdateProduct.mutate({ ...params, prod_tk: product?.token });
    } else {
      await createProduct(params);
    }
  };

  useEffect(() => {
    if (!createProductError) return;

    const dataErrors = createProductError as ICreateError;

    if (dataErrors?.errors) {
      setErrors(formatErrors(dataErrors.errors));
    }
  }, [createProductError]);

  function formatErrors(errors: any) {
    let formatted = {};

    const locales = LANGUAGES.map((lang) => lang.iso);

    for (const key in errors) {
      if (locales.includes(key.split(".")?.[1])) {
        const temp = key.split(".");
        const newKey = `${temp[0]}[${temp[1]}]`;
        formatted[newKey] = errors[key];
      }
    }

    return formatted;
  }

  const handleDelete = async (price_id) => {
    if (!price_id) return;

    try {
      await client.product.prices.delete(price_id);
      invalidateProducts();
      setTrashedPrices((prev) => {
        const foundPrice = productPrices.find((price) => price.id === price_id);
        return foundPrice ? [...prev, foundPrice] : prev;
      });
      setProductPrices((prev) => prev.filter((price) => price.id !== price_id));
      snackHandler("Price deleted", "success");
    } catch (error) {
      snackHandler("Failed to delete price", "error");
    }
  }

  const handleRestore = async (price_id) => {
    if (!price_id) return;

    try {
      await client.product.prices.restore(price_id);
      invalidateProducts();
      setProductPrices((prev) => {
        const foundPrice = trashedPrices.find((price) => price.id === price_id);
        return foundPrice ? [...prev, foundPrice] : prev;
      });
      setTrashedPrices((prev) => prev.filter((price) => price.id !== price_id));
      snackHandler("Price restored", "success");
    } catch (error) {
      snackHandler("Failed to restore price", "error");
    }
  }

  const handleProductPricesChange = (newValue) => {
    // Map selected countries to the `Prices` format

    const deletedChips = productPrices
      .filter((prevPrice) =>
        !newValue.some((newItem) => newItem.key.toLowerCase() === prevPrice.country)
      );

    if (deletedChips.length > 0) {
      deletedChips.forEach((item) => {
        if (!item?.temp_id) {
          handleDelete(item.id);
        } else {
          setProductPrices((prev) => prev.filter((price) => price.temp_id !== item.temp_id));
        }
      });

      return;
    }

    //if new value is inside the trash, restore it
    const restoredChips = newValue
      .filter((newItem) =>
        trashedPrices.some((price) => price.country === newItem.key.toLowerCase())
      );

    if (restoredChips.length > 0) {
      const restoredPrices = trashedPrices.filter((price) =>
        restoredChips.some((newItem) => price.country === newItem.key.toLowerCase())
      );

      if (restoredPrices.length > 0) {
        restoredPrices.forEach((item) => {
          handleRestore(item.id);
        });
      }
      return;
    }

    const updatedPrices = newValue.map((option) => {
      const existingPrice = productPrices.find((price) => {
        if (price.country === option.key.toLowerCase()) {
          return {
            ...price,
            label: option.value,
          }
        }
      });

      // Preserve existing details if available, or create a new entry
      return existingPrice || {
        temp_id: uuid(),
        country: option.key.toLowerCase(),
        locale: option.locale,
        currency: option.currency,
        price: 0, // Default price
        details: '',
        terms: '',
        onboarding_link: '',
        product_pdf: '',
        label: option.value,
      };
    });

    setProductPrices(updatedPrices);
  };

  useEffect(() => {
    if (data) {
      const newData = clone(data as any);

      if (isCopy) {
        delete newData?.token;
        setData(null);
      }

      setProduct(newData);

      if (newData?.course_info?.months) {
        setCourseMonths(Number(newData.course_info.months));
      }
    }
  }, [data, isCopy]);

  useEffect(() => {
    if (!data || !countries) return;

    const prices = data?.prices.map((price) => {
      const country = countries?.find((c) => c.key.toLowerCase() === price.country);
      return {
        ...price,
        label: country?.value,
      };
    }) as ProductPrices[];

    const trashedPrices = prices.filter((price) => price?.deleted_at);

    setTrashedPrices(trashedPrices);
    setProductPrices(prices.filter((price) => !price?.deleted_at));
  }, [data, countries]);

  return (
    <EduModal
      fullWidth
      open={show}
      onClose={onClose}
      maxWidth="md"
    >
      <EduModalTitle onClose={onClose}>
        <img
          src={data?.token ? editIcon : addIcon}
          className="icon"
        />
        {isCopy && !data?.token
          ? "Add New Product from copy"
          : data?.token
            ? "Edit Product"
            : "Add New Product"}
        {isAdmission && " Tutoring"}
      </EduModalTitle>

      <EduModalBody>
        <Box
          id="main-form"
          ref={formRef}
          onSubmit={submitForm}
          component="form"
          className="flex flex-col gap-4 m-5 mt-6"
        >
          <Box className="grid grid-cols-3 gap-4">
            <TextField
              required
              fullWidth
              name="product_tag"
              label="Tag"
              defaultValue={product?.product_tag ?? ""}
              className="col-span-1"
            />

            {
              data?.token && (
                <TextField
                  fullWidth
                  name="price"
                  label="Default Price"
                  disabled
                  sx={{
                    "& .Mui-disabled": {
                      border: "none !important",
                    },
                  }}
                  onChange={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9.]/g, ""))
                  }
                  defaultValue={product?.price ? Number(product?.price) / 100 : ""}
                />
              )
            }


            <TextField
              select
              fullWidth
              name="guaranteed_level"
              label="Guaranteed Level"
              defaultValue={product?.guaranteed_level ?? ""}
            // onChange={handleChangeEdit}
            >
              {ENGLISH_LEVELS?.map((l, i: number) => (
                <MenuItem
                  key={l + i}
                  value={l}
                >
                  {l}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box className="flex flex-nowrap items-stretch gap-4">
            <Button
              variant="outlined"
              onClick={() => setCourseMonths((p) => p - 1)}
              disabled={Number(product?.course_info?.months) < 2}
              className="!text-2xl font-bold"
            >
              -
            </Button>

            <Box
              sx={{ borderColor: theme.palette.primary.main }}
              className="border rounded flex justify-center items-center grow select-none py-2"
            >
              Structure for {courseMonths} Month{courseMonths != 1 && "s"}
            </Box>

            <Button
              variant="outlined"
              onClick={() => setCourseMonths((p) => p + 1)}
              className="!text-2xl font-bold"
            >
              +
            </Button>
          </Box>

          <Accordion className="!rounded-lg !m-0">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Structure {courseMonths} months
            </AccordionSummary>

            <AccordionDetails className="!pt-0">
              <Box className="flex flex-col gap-2">
                {Array.from({ length: courseMonths }).map((_, i: number) => (
                  <Box
                    key={i}
                    className="flex flex-col gap-3"
                  >
                    <Box
                      sx={{ color: theme.palette.primary.main }}
                      className="text-sm"
                    >
                      Structure month {i + 1}
                    </Box>

                    <Box className="flex flex-row gap-4 flex-nowrap">
                      {Object.keys({ ...courseStructureDefault }).map(
                        (type: string) => (
                          <TextField
                            fullWidth
                            key={type + i}
                            name={`course_info[course_structure][${i}][${type}]`}
                            label={fUpper(type.replaceAll("_", " "))}
                            defaultValue={
                              product?.course_info?.course_structure?.[i]?.[
                              type
                              ] ?? 0
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        )
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion className="!rounded-lg !m-0">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Structure week for all {courseMonths} months
            </AccordionSummary>

            <AccordionDetails className="!pt-0">
              <Box className="flex flex-col gap-2">
                {Array.from({ length: courseMonths }).map((_, i: number) => (
                  <Box
                    key={i}
                    className="flex flex-col gap-3"
                  >
                    <Box
                      sx={{ color: theme.palette.primary.main }}
                      className="text-sm"
                    >
                      Structure one week for month {i + 1}
                    </Box>

                    <Box className="flex flex-row gap-4 flex-nowrap">
                      {Object.keys({ ...courseStructureDefault }).map(
                        (type: string) => (
                          <TextField
                            fullWidth
                            key={type + i}
                            name={`course_info[course_structure_week][${i}][${type}]`}
                            label={fUpper(type.replaceAll("_", " "))}
                            defaultValue={
                              product?.course_info?.course_structure_week?.[
                              i
                              ]?.[type] ?? 0
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        )
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box className="flex flex-col gap-4 m-5">
          <Box className="flex flex-col gap-4">
            <Autocomplete
              multiple
              options={countries ?? []}
              getOptionLabel={(option) => option?.value}
              value={productPrices
                .map((price) => countries?.find((country) => country.key.toLowerCase() === price.country))
                .filter(Boolean) // Filter out undefined values
              }
              onChange={(_, newValue) => handleProductPricesChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Countries"
                  placeholder="Countries"
                  variant="outlined"
                  fullWidth
                />
              )}
              isOptionEqualToValue={(option, value) => option.key.toLowerCase() === value.key.toLowerCase()}
            />
          </Box>

          {productPrices.map((price, i) => (
            <PriceBox
              i={price.country + i}
              key={price.country + i}
              price={price}
              product_id={product?.id}
              setProductPrices={setProductPrices}
            />
          ))}

          <LoadingButton
            form="main-form"
            type="submit"
            color="primary"
            variant="outlined"
            loading={isLoading}
            className="!py-2 !ml-auto"
          >
            <img
              src={saveIcon}
              className={`icon ${isLoading ? "opacity-0" : ""}`}
            />
            SAVE
          </LoadingButton>
        </Box>

      </EduModalBody>
    </EduModal>
  );
}
