import { IExercise } from "../ExercisesV2/Create/interfacesAndDefaults";
import { PotExercise } from "./exercisesV3.types";

export const typeCategoryMappingDummy = {
  // IELTS: ["Reading", "Writing", "Listening", "Speaking"],
  GRAMMAR: [],
  COMPREHENSION: ["Listening", "Reading"],
  // LINGUASKILL: ["Reading", "Writing", "Listening", "Speaking"],
};

export const levelMappingDummy = ["Beginner","Basic", "Intermediate","Advanced"];

export const NAME_SELECT = [
  "IELTS 0",
  "IELTS 0 - TEST 0",
  "IELTS 0 - TEST 0 - PART 0",
  "GRAMMAR TEST 0",
];


export const transformExercisePayload = (exercise: PotExercise | undefined) => {
  if (exercise) {
    const {
      audio_file,
      category,
      definitions,
      duration,
      hasDeadLine,
      img_file,
      name,
      text,
      type,
      multiple_choice_true_false,
      matching_option_gap_fill,
      groupings,
      id
    } = exercise;
    const newExercise: IExercise = {
      id: id.toString(),
      audio_file,
      category,
      definitions,
      duration: Number(duration),
      hasDeadLine: !!hasDeadLine,
      img_file,
      name,
      text,
      type,
      exercises: {
        multiple_choice_true_false,
        matching_option_gap_fill,
        groupings,
      },
    };
    return newExercise;
  }
  return undefined;
};
