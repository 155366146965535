import React, { useState } from "react";
import useAuth from "@/hooks/useAuth";
import { queryClient } from "@/main";
import { IeltsExercise } from "@/pages/Exercises/ExercisesV3/exercisesV3.types";
import { useExerciseContext } from "../../state/ExerciseContext";
import { createExercise, updateExercise } from "@/api";
import {
  CATEGORIES,
  TYPE_TO_CATEGORIES,
  OLD_TYPE_TO_CATEGORIES,
  CATEGORY_TO_ENGLISH_LEVEL,
} from "@/pages/Exercises/ExercisesV3/components/old-exercise(ielts)/constants";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  InputLabel,
  FormControl,
  ListItemText,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import TodoList from "@/pages/Exercises/ExercisesV1/components/TodoList";
import LANGUAGES from "@/components/assets/LANGUAGES";
import SelectFile from "@/pages/Exercises/ExercisesV1/components/SelectFile";
import SelectParts from "@/pages/Exercises/ExercisesV1/components/SelectParts";
import InputNumber from "@/pages/Exercises/ExercisesV1/components/InputNumber";
import InputTextSelect from "@/pages/Exercises/ExercisesV1/components/InputTextSelect";
import SelectInputFile from "@/pages/Exercises/ExercisesV1/components/SelectInputFile";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export const AddOldExercise: React.FC = ({
  exercisePayload,
}: {
  exercisePayload?: IeltsExercise;
}) => {
  return <CreateOldExercise exercisePayload={exercisePayload} />;
};

const CreateOldExercise = ({
  exercisePayload,
}: {
  exercisePayload?: IeltsExercise;
}) => {
  const theme = useTheme();

  const [error, setError] = useState<any>({});
  // old-exercise(ielts) exercise state management
  const [exercise, setExercise] = useState<any>(exercisePayload || {});
  const { queryKey } = useExerciseContext();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [availableCategories, setAvailableCategories] = useState(CATEGORIES);
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [questionMediaFiles, setQuestionMediaFiles] = useState<File[]>([]);
  const { userAdmin, snackHandler } = useAuth();

  const handleAddTodo = () =>
    setExercise((p: any) => ({
      ...p,
      questions: {
        ...p?.questions,
        [Object.keys(exercise?.questions || {}).length + 1]: {
          question_type: "",
          correct_answer: "",
        },
      },
    }));

  // old-exercise(ielts) type selection
  const handleSetType = (selectedItem: string) => {
    if (selectedItem !== "SELECT") {
      setExercise((p: any) => {
        const updatedExercise = { ...p };
        updatedExercise["type"] = selectedItem;
        delete updatedExercise["category"];
        return updatedExercise;
      });
      setAvailableCategories(TYPE_TO_CATEGORIES[selectedItem] || CATEGORIES);
    } else {
      setExercise((p: any) => {
        const updatedExercise = { ...p };
        delete updatedExercise["type"];
        return updatedExercise;
      });
      setAvailableCategories(CATEGORIES);
    }

    if (selectedItem === "Writing") {
      setExercise((p: any) => {
        const t = { ...p };
        delete t["questions"];
        return t;
      });
    }
  };

  const confirm = async (values: any) => {
    const formData = new FormData();

    if (mediaFiles.length < 1) {
      setError((p: any) => ({ ...p, [values]: true }));
    }

    if (exercise?.category?.[0]?.toLowerCase().trim() === "reading") {
      if (questionMediaFiles.length < 1) {
        setError((p: any) => ({ ...p, [values]: true }));
      }
    }

    // Append other values to formData
    for (const key in values) {
      formData.append(key, values[key]);
    }

    // append media files to the form
    if (
      mediaFiles.length > 0 &&
      exercise?.category?.[0]?.toLowerCase().trim() === "reading"
    ) {
      mediaFiles.forEach((file, index) => {
        formData.append(`files[media${index + 1}]`, file);
      });
    } else if (mediaFiles.length > 0) {
      mediaFiles.forEach((file) => {
        formData.append(`files[media]`, file);
      });
    } else {
      formData.delete("files");
    }

    if (
      exercise?.category?.[0]?.toLowerCase().trim() === "listening" &&
      typeof exercise?.files?.audio === "object"
    ) {
      formData.append("files[audio]", exercise?.files?.audio);
    }

    // append question files to the form
    if (questionMediaFiles.length > 0) {
      questionMediaFiles.forEach((file, index) => {
        formData.append(`files[question${index + 1}]`, file);
      });
    } else {
      // formData.append(`files`, file);
    }

    try {
      if (exercisePayload) {
        await updateExercise({ tk: userAdmin, formData });
        snackHandler("Exercise Updated successfully", "success");
      } else {
        await createExercise({ tk: userAdmin, formData });
        snackHandler("Exercise Created successfully", "success");
      }
    } catch (error) {
      snackHandler("Error", "error");
    } finally {
      setOpenConfirm(false);
    }

    queryClient.invalidateQueries(queryKey);
  };

  const create = () => {
    const v = { ...exercise };

    if (v?.category === "Writing") {
      if ("n_questions" in v) v.n_questions = null;
      if ("questions" in v) v.questions = null;
    } else {
      if ("count" in v) v.count = null;
      if ("questions" in v) v.questions = JSON.stringify(v?.questions);
    }

    if (v?.category?.[0] !== "Listening") {
      v["files.audio"] = null;
    }

    ["type", "name"].forEach((e) => {
      if (!v[e]) {
        setError((p: any) => ({ ...p, [e]: true }));
      }
    });

    if (v?.type && v?.name) {
      v.english_level = (v.category || []).flatMap(
        (category: string) => CATEGORY_TO_ENGLISH_LEVEL[category] || []
      );

      confirm(v);
    }
  };

  return (
    <>
      <FormControl className="h-full w-full">
        <Box className="flex items-stretch gap-8">
          <Box
            sx={{ width: "calc(100% - 80%)", minWidth: "calc(100% - 80%)" }}
            className="flex flex-col gap-12"
          >
            <Box className="flex flex-col gap-4">
              <TextField
                required
                name="name"
                label="Name"
                className="w-full"
                value={exercise?.name || ""}
                onChange={(e) => {
                  setExercise((p: any) => ({ ...p, name: e.target.value }));
                }}
                placeholder="Exercise Name"
              />

              <InputTextSelect
                required
                label="Type"
                value={exercise?.type ?? ""}
                setValue={handleSetType}
                placeholder="Exercise type"
              >
                {Object.keys(TYPE_TO_CATEGORIES).map((type) => (
                  <MenuItem
                    key={type}
                    value={type}
                  >
                    {type}
                  </MenuItem>
                ))}
              </InputTextSelect>

              <InputNumber
                value={exercise?.timer || exercise?.duration || null}
                error={error.timer && !exercise?.timer}
                setValue={(v: number) =>
                  setExercise((p: any) => ({ ...p, timer: v }))
                }
              />

              <FormControl className="w-full">
                <InputLabel id="lang-select-label">Language</InputLabel>
                <Select
                  multiple
                  label="Language"
                  value={exercise?.lang || []}
                  labelId="lang-select-label"
                  onChange={(e) =>
                    setExercise((p: any) => ({
                      ...p,
                      lang: e.target.value,
                    }))
                  }
                  renderValue={(selected) =>
                    Array.isArray(selected) ? selected.join(", ") : ""
                  }
                >
                  {LANGUAGES.map((lang) => (
                    <MenuItem
                      key={lang.iso}
                      value={lang.iso}
                    >
                      <Checkbox
                        checked={(exercise?.lang || []).indexOf(lang.iso) > -1}
                      />
                      <ListItemText primary={lang.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className="w-full">
                <InputLabel id="category-select-label">Category</InputLabel>
                <Select
                  multiple
                  label="Category"
                  value={exercise?.category || []}
                  labelId="category-select-label"
                  onChange={(e) => {
                    setExercise((p: any) => ({
                      ...p,
                      category: e.target.value,
                    }));
                  }}
                  renderValue={(selected) =>
                    Array.isArray(selected) ? selected.join(", ") : ""
                  }
                >
                  {(exercise?.type === "GRAMMAR"
                    ? OLD_TYPE_TO_CATEGORIES.GRAMMAR
                    : availableCategories
                  ).map((category) => (
                    <MenuItem
                      key={category}
                      value={category}
                    >
                      <Checkbox
                        checked={
                          (exercise?.category || []).indexOf(category) > -1
                        }
                      />
                      <ListItemText primary={category} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {Boolean((exercise?.category || []).includes("Writing")) && (
                <>
                  <TextField
                    name="essay_id"
                    label="Essay checker ID"
                    className="w-full"
                    value={exercise?.essay_id || ""}
                    onChange={(e) => {
                      setExercise((p: any) => ({
                        ...p,
                        essay_id: e.target.value,
                      }));
                    }}
                    placeholder="Essay checker ID"
                  />

                  <TextField
                    name="topic"
                    label="Essay checker topic"
                    className="w-full"
                    value={exercise?.topic || ""}
                    onChange={(e) => {
                      setExercise((p: any) => ({
                        ...p,
                        topic: e.target.value,
                      }));
                    }}
                    placeholder="Essay checker topic"
                  />
                </>
              )}

              <FormControlLabel
                label="Is Precourse"
                sx={{
                  color: theme.palette.primary.main,
                  bgcolor:
                    theme.palette.mode == "dark" ? "transparent" : "white",
                  borderColor: theme.palette.primary.main,
                }}
                className="rounded-[0.6rem] border h-[2.7rem] !mx-0"
                control={
                  <Checkbox
                    checked={exercise.is_precourse || false}
                    onChange={(e) =>
                      setExercise((p: any) => ({
                        ...p,
                        is_precourse: e.target.checked,
                      }))
                    }
                  />
                }
              />
            </Box>

            {Boolean((exercise?.category || []).includes("Writing")) && (
              <Box className="flex flex-col gap-4">
                <SelectParts
                  value={exercise?.count || 0}
                  setValue={(v: string | number) =>
                    setExercise((p) => ({ ...p, count: v }))
                  }
                />
              </Box>
            )}

            <Box className="flex flex-col gap-4">
              {/* reading select file */}
              <SelectInputFile
                id="PDF"
                files={mediaFiles}
                onFilesChanged={setMediaFiles}
                buttonName={exercise?.files?.media || "Select a PDF File(s)"}
              />

              {/* questions select file */}
              {Boolean((exercise?.category || []).includes("Reading")) && (
                <SelectInputFile
                  id="questionsPDF"
                  files={questionMediaFiles}
                  onFilesChanged={setQuestionMediaFiles}
                  buttonName="Select Question File(s)"
                />
              )}

              {Boolean((exercise?.category || []).includes("Listening")) && (
                <SelectFile
                  id="MPEG"
                  accept=".mp3"
                  value={exercise?.files?.audio || ""}
                  setValue={(v: any) =>
                    setExercise((p: any) => ({
                      ...p,
                      files: { ...p?.files, audio: v },
                    }))
                  }
                >
                  Load MP3
                </SelectFile>
              )}
            </Box>

            <Box className="mt-auto">
              <Button
                sx={{ p: "0.3rem" }}
                variant="outlined"
                onClick={() => setOpenConfirm(true)}
                className="w-full"
              >
                Save
              </Button>
            </Box>
          </Box>

          <Box sx={{ height: "100%", width: "100%" }}>
            <TodoList
              type={exercise?.category}
              {...{ exercise: exercise, setExercise: setExercise }}
              onAddTodo={handleAddTodo}
            />
          </Box>
        </Box>
      </FormControl>

      <ConfirmationDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={create}
      >
        Do you want to create this exercise?
      </ConfirmationDialog>
    </>
  );
};

export default CreateOldExercise;
