import { memo } from "react";
import useAuth from '@/hooks/useAuth';
import { ROLES, STATUS } from "./interfaces";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import addIcon from "@/assets/icons/add.svg";
import Dropdown from "@/components/_Common/Dropdown";
import filterIcon from "@/assets/icons/filter.svg";
import refreshIcon from "@/assets/icons/refresh.svg";
import downloadIcon from "@/assets/icons/download.svg";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import { queryClient } from "@/main";

function Toolbar({
  key_query,
  periodRange,
  handleCreate,
  periodRanges,
  handleDownload,
  isDisableDownload,
  page,
  ...rest
}: any) {
  const { isMember } = useAuth();

  return (
    <Box
      className="flex !items-stretch"
      id="filter_bar"
    >
      <Button
        variant="outlined"
        onClick={() => {
          rest?.setFilters(() => {
            const p = new URLSearchParams();
            p.set("profile", "on");
            p.set("page", page);
            p.set("period_range", dayjs().format("MMMM YYYY"));
            return p;
          });

          queryClient.invalidateQueries(key_query);
        }}
      >
        <img
          src={refreshIcon}
          className="icon"
        />
        Refresh
      </Button>

      {!isMember && (
        <Button
          variant="outlined"
          className="!pl-3"
          onClick={handleCreate}
        >
          <img
            src={addIcon}
            className="icon"
          />
          Add new tutor
        </Button>
      )}

      <Dropdown
        text="Filter"
        icon={filterIcon}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Box className="p-4 flex flex-col gap-4 min-w-[15rem]">
          <TextField
            select
            fullWidth
            label="Period"
            value={rest?.filters?.period_range ?? ""}
            onChange={(e: any) => {
              rest?.setFilters((p: any) => {
                p.set("period_range", e.target.value);
                return p;
              });
            }}
          >
            {periodRanges?.map((e: any, i: number) => (
              <MenuItem
                key={e.start_month + i}
                value={e.start_month}
              >
                <Box className="w-full flex justify-between gap-4">
                  <span>{e.start_month}</span>
                  <span>
                    {e?.period_range
                      ?.split("/")
                      ?.map((e: string) => dayjs(e).format("DD/MM"))
                      ?.join(" - ") || ""}
                  </span>
                </Box>
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            label="Status"
            value={rest?.filters?.status ?? ""}
            onChange={(e: any) => {
              rest?.setFilters((p: any) => {
                p.set("status", e.target.value);
                return p;
              });
            }}
          >
            {STATUS.map((r, i) => (
              <MenuItem
                key={r + i}
                value={r}
              >
                {r}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            label="Type"
            value={rest?.filters?.type ?? ""}
            onChange={(e: any) => {
              rest?.setFilters((p: any) => {
                p.set("type", e.target.value);
                return p;
              });
            }}
          >
            {ROLES.map((r, i) => (
              <MenuItem
                key={r + i}
                value={r}
              >
                {r}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            label="Profile"
            value={rest?.filters?.profile || "both"}
            onChange={(e: any) => {
              rest?.setFilters((p: any) => {
                if (e.target.value == "both") {
                  p.delete("profile");
                } else {
                  p.set("profile", e.target.value);
                }
                return p;
              });
            }}
          >
            {["both", "on", "off"].map((r, i) => (
              <MenuItem
                key={r + i}
                value={r}
              >
                {r.toUpperCase()}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Dropdown>

      {!isMember && (
        <Button
          title="Download"
          disabled={isDisableDownload}
          variant="outlined"
          onClick={handleDownload}
        >
          <img
            src={downloadIcon}
            className="icon"
          />
          Download
        </Button>
      )}

      <SearchToolbar
        {...rest}
        client={true}
      />
    </Box>
  );
}

export default memo(Toolbar);