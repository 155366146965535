import { useState } from "react";
import useAuth from '@/hooks/useAuth';
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteExercise, getUsersWithExercises } from "@/api";
import useDGS from "@/hooks/useDataGridSort";
import BasePage from "@/components/Page/Base";
import AllExercises from "@/pages/CrmExercises/AllExercises";
import usePagination from "@/hooks/usePagination";

const ExercisesDone = () => {
  const { userAdmin } = useAuth();

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ setFilters, filters });
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const sorts = filterData?.sorts;
  delete filterData?.sorts;
  delete filterData?.page;
  const keyCache = ["getUsersWithExercises", page, filterData, sorts];
  const { data, isLoading } = useQuery(
    keyCache,
    () =>
      getUsersWithExercises({
        tk: userAdmin.token,
        params: { page, filter: filterData, sorts },
      }),
    { keepPreviousData: true }
  );

  const pagination = {
    total: data?.total ? data?.total : 1,
    last_page: data?.last_page ? data?.last_page : 1,
    current_page: data?.current_page ? data?.current_page : 1,
  };

  const [idUser, setIdUser] = useState("");
  const [tokenEx, setTokenEx] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const updateDeleteExercise = useMutation(deleteExercise, {
    onSuccess() {
      setTokenEx("");
      setOpenConfirmDialog(false);
      queryClient.invalidateQueries(keyCache);
    },
  });

  return (
    <BasePage title="Exercises Done">
      <AllExercises
        id={userAdmin.token}
        page={page}
        idUser={idUser}
        setPage={setPage}
        tokenEx={tokenEx}
        filters={filterData}
        setIdUser={setIdUser}
        isLoading={isLoading}
        sortModel={sortModel}
        exercises={data?.data || []}
        setTokenEx={setTokenEx}
        pagination={pagination}
        setFilters={setFilters}
        openConfirmDialog={openConfirmDialog}
        onSortModelChange={onSortModelChange}
        updateDeleteExercise={updateDeleteExercise}
        setOpenConfirmDialog={setOpenConfirmDialog}
      />
    </BasePage>
  );
};

export default ExercisesDone;
