import { euro } from "../useHelpers";
import { AddRounded, MoreVert, Public } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Switch,
  MenuItem,
  useTheme,
  MenuList,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
} from "@mui/material";
import cn from "classnames";
import dayjs from "dayjs";
import useDGS from "@/hooks/useDataGridSort";
import Dropdown from "../_Common/Dropdown";
import hideIcon from "@/assets/icons/hide.svg";
import editIcon from "@/assets/icons/edit.svg";
import MyTooltip from "../MyTooltip";
import useScreen from "@/hooks/useScreen";
import FlagRender from "../FlagRender";
import deleteIcon from "@/assets/icons/delete.svg";
import usePagination from "@/hooks/usePagination";
import ToolbarProdotti from "./ToolbarProdotti";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

export interface IProduct {
  token?: string;
  id?: number;
  price?: number;
  product_tag?: string;
  stripe_tk_prod?: string;
  created_at?: string;
  timer?: number;
  percorso_token?: string;
  terms?: string;
  policy?: string;
  tutoring?: number;
  course_info: {
    course_name: string;
    hours?: {
      speaking: number;
      grammar: number;
      ielts: number;
      one_to_one: number;
      writing_correction: number;
    };
    course_structure: Array<{
      speaking: number;
      grammar: number;
      ielts: number;
      one_to_one: number;
      writing_correction: number;
    }>;
    months: number;
  };
}

const ProductsTable = ({
  data,
  dataToogle,
  setShowDialog,
  setShowDialogDiscount,
  toogleProductFormDialog,
  toogleConfirmHideDialog,
  toogleConfirmDeleteDialog,
  handleToogleAddToLanguagesForSelect,
}) => {
  const theme = useTheme();
  const { perPage } = useScreen();

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ setFilters, filters });
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const columns: GridColDef[] = [
    {
      field: "price",
      headerName: "Price",
      minWidth: 80,
      flex: 0.3,
      valueFormatter: (params) => {
        if (!params.value) return "-";
        return euro(Number(params.value) / 100);
      },
    },
    {
      field: "product_tag",
      headerName: "Tag",
      minWidth: 300,
      flex: 1,
      renderCell: ({ row }) => <MyTooltip>{row.product_tag}</MyTooltip>,
    },
    {
      field: "supported_languages",
      headerName: "Supported languages",
      flex: 0.7,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const langs = row?.prices?.map((price) => !price.deleted_at ? price.country : null).filter(Boolean);

        if (langs.length === 0) return "-";

        return (
          <div className="flex gap-2">
            {langs.map((lang, index) => {
              return (
                <FlagRender
                  key={lang + index}
                  flag={lang}
                  className="w-[26px]"
                />
              );
            })}
          </div>
        );
      },
    },
    {
      field: "count",
      headerName: "payments",
      minWidth: 90,
      flex: 0.5,
    },
    {
      field: "created_at",
      headerName: "Created Date",
      minWidth: 110,
      flex: 0.5,
      valueFormatter: (params) => {
        if (!params.value) return "";
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Dropdown text={<MoreVert />}>
            <MenuList>
              <MenuItem
                color="primary"
                title="Copy product"
                onClick={() => toogleProductFormDialog(row, true)}
              >
                <ListItemIcon>
                  <AddRounded fontSize="medium" />
                </ListItemIcon>
                <ListItemText>Copy product</ListItemText>
              </MenuItem>

              <MenuItem
                title="Edit"
                color="primary"
                onClick={() => toogleProductFormDialog(row)}
              >
                <ListItemIcon>
                  <img
                    src={editIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>

              <MenuItem
                title="Hide"
                color="primary"
                onClick={() => toogleConfirmHideDialog(row)}
              >
                <ListItemIcon>
                  <img
                    src={hideIcon}
                    className="icon no-margin h-[1.2rem]"
                  />
                </ListItemIcon>
                <ListItemText>Hide</ListItemText>
              </MenuItem>

              <Dropdown
                hideBgActive
                text="Toogle select for crm Meeting done"
                className={cn(
                  "gap-4 !border-none !font-medium !text-[1rem]",
                  theme.palette.mode == "dark"
                    ? "!text-white/[.85]"
                    : "!text-black/[.85]"
                )}
                iconComponent={<Public />}
              >
                <MenuList>
                  {Object.keys(dataToogle ?? {}).map((lang: string) => (
                    <Box key={lang}>
                      <FormControlLabel
                        label={lang?.toUpperCase()}
                        control={
                          <Switch
                            name={lang}
                            checked={dataToogle?.[lang]?.includes(row?.id)}
                            onChange={(e) => {
                              handleToogleAddToLanguagesForSelect(
                                row?.id,
                                lang,
                                e.target.checked
                              );
                            }}
                          />
                        }
                        className="w-full !m-0 pl-2 pr-4 hover:bg-gray-50/5"
                      />
                    </Box>
                  ))}
                </MenuList>
              </Dropdown>

              <MenuItem
                title="Delete"
                color="primary"
                onClick={() => toogleConfirmDeleteDialog(row, row.count)}
              >
                <ListItemIcon>
                  <img
                    src={deleteIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </MenuList>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <DataGrid
      autoHeight
      disableSelectionOnClick
      sx={getTableStyling(theme)}
      rows={data}
      page={parseInt(page.toString()) - 1}
      columns={columns}
      pageSize={perPage}
      sortModel={sortModel}
      components={{
        Toolbar: ToolbarProdotti,
        Pagination: CustomPaginationClient,
      }}
      onPageChange={(newPage) => setPage(newPage + 1)}
      initialState={{
        sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
      }}
      componentsProps={{
        toolbar: {
          setShowDialog,
          setShowDialogDiscount,
          filters: filterData,
          setFilters,
        },
      }}
      onSortModelChange={onSortModelChange}
      rowsPerPageOptions={[perPage]}
      experimentalFeatures={{ newEditingApi: false }}
    />
  );
};

export default ProductsTable;
