import { useRef } from "react";
import { ExerciseTable } from "./components/ExerciseTable";
import { ExerciseContextProvider } from "@/pages/Exercises/ExercisesV3/state/ExerciseContext";
import ToolBar from "@/pages/Exercises/ExercisesV3/components/Toolbar";
import BasePage from "@/components/Page/Base";

export default () => {
  const watchImgRef = useRef(null);
  const playerRef = useRef(null);

  return (
    <ExerciseContextProvider>
      <BasePage title="Exercises">
        <ToolBar
          playerRef={playerRef}
          watchImgRef={watchImgRef}
        />

        <ExerciseTable />
      </BasePage>
    </ExerciseContextProvider>
  );
};
