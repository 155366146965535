import {
  Dispatch,
  useState,
  createRef,
  useEffect,
  SetStateAction,
} from "react";
import { clone } from "@/components/useHelpers";
import { format } from "date-fns";
import useAuth from '@/hooks/useAuth';
import { MoreVert } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid";
import {
  INEdit,
  INDestroy,
  INFeedback,
  INEditPayRate,
  INAccessEvents,
  INDataFeedback,
} from "./interfaces";
import {
  Box,
  Avatar,
  Tooltip,
  MenuItem,
  MenuList,
  useTheme,
  ListItemText,
} from "@mui/material";
import Comments, { INComments } from "@/components/User/Comments";
import DialogTutors, {
  DEFAULT_FEEDBACKS,
} from "../Feedback/TutorsFeedbacks/TutorsDialog";
import CheckTutorSetup, {
  IRef as IRefCheckTutorSetup,
} from "./CheckTutorSetup";
import DataAvailabilities, {
  IRef as IRefDataAvailabilities,
} from "../Availabilities/dialog/DataAvailabilities";
import PaymentRequestDialog, {
  IForwardRef as IRefPaymentRequestDialog,
} from "./PaymentRequestDialog";
import Edit from "./Edit";
import Create from "./Create";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import Toolbar from "./Toolbar";
import BasePage from "@/components/Page/Base";
import Dropdown from "@/components/_Common/Dropdown";
import useScreen from "@/hooks/useScreen";
import MyTooltip from "@/components/MyTooltip";
import EditPayRate from "./EditPayRate";
import AccessEvents from "./AccessEvents";
import useExportCSV from "@/components/useExportCSV";
import usePagination from "@/hooks/usePagination";
import ConfirmDialog from "@/pages/Exercises/ExercisesV1/components/ConfirmDialog";
import useDownloadImg from "@/components/useDownloadImg";
import CustomPagination from "@/components/_Common/CustomPagination";

export const PUBLIC_STORAGE = import.meta.env.VITE_BASE_URL_SERVER;

const VITE_ROUTE_ACADEMY_TUTOR = import.meta.env.VITE_ROUTE_ACADEMY_TUTOR;

export const KEY_QUERY: Array<any> = ["general-tutors"];

export const HTTP_ROUTE_BASE = "/v1/general/tutors";
export const HTTP_ROUTE_CREATE = HTTP_ROUTE_BASE + "/create/admin-V2";
const HTTP_ROUTE_EDIT = HTTP_ROUTE_BASE + "/edit";
const HTTP_ROUTE_DELETE = HTTP_ROUTE_BASE + "/delete";
const HTTP_ROUTE_HIDDEN = HTTP_ROUTE_BASE + "/hidden";
const HTTP_ROUTE_ACCESS = HTTP_ROUTE_BASE + "/access-events";
export const HTTP_ROUTE_FEEDBACK = HTTP_ROUTE_BASE + "/feedback";
const HTTP_ROUTE_AVAILABILITIES_DOWNLOAD =
  HTTP_ROUTE_BASE + "/availabilities/download";

const HTTP_ROUTE_ACTIVATE_INVOICE_SUBMISSION =
  HTTP_ROUTE_BASE + "/reactivate-invoice/";

const HTTP_ROUTE_AVAILABILITIES_SHOW = "/v1/availabilities/show";

const Tutors = () => {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { snackHandler, copyToClipboard } = useAuth();

  const refCheckTutorSetup = createRef<IRefCheckTutorSetup>();
  const refDataAvailabilities = createRef<IRefDataAvailabilities>();
  const refPaymentRequestDialog = createRef<IRefPaymentRequestDialog>();

  const [edit, setEdit] = useState<INEdit>();
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [destroy, setDestroy] = useState<INDestroy>();
  const [comments, setComments] = useState<INComments>();
  const [feedbacks, setFeedbacks] = useState<INFeedback>(DEFAULT_FEEDBACKS);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [editPayRate, setEditPayRate] = useState<INEdit>();
  const [accessEventsData, setAccessEventsData] = useState<INAccessEvents>();
  const [isDisableDownload, setIsDisableDownload] = useState<boolean>(false);

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ setFilters, filters });
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const filtersForAPI = { ...filterData };
  delete filtersForAPI.page;

  const key_query = KEY_QUERY.concat([filtersForAPI]);

  const fetchData = () =>
    useAPI(HTTP_ROUTE_BASE, {
      page,
      filters: { ...filtersForAPI, per_page: perPage },
    });

  const { data: dataAPI, isLoading } = useQuery(
    [key_query, page, perPage],
    fetchData
  );

  const { tutors, period_range: periodRanges } = dataAPI?.data || {};

  // EDIT
  const handleOnEdit = async (): Promise<any> => {
    setEdit((p: INEdit | undefined) => ({
      ...p,
      errors: undefined,
      disabled: true,
    }));

    const newData = clone(edit?.newData as any);

    if (newData?.profile_pic) {
      newData.profile_pic = edit?.newData?.profile_pic;
    }

    if (newData?.role?.length) {
      newData.role = newData?.role?.join(",") ?? "";
    }

    const formData = new FormData();

    for (const key in newData) {
      formData.append(key, newData[key]);
    }

    const res = await useAPI(
      HTTP_ROUTE_EDIT,
      { token: edit?.defaultData?.token, ...newData },
      { useErrors: true, headers: { "Content-Type": "multipart/form-data" } }
    );

    // ERRORS
    if (!res?.status) {
      snackHandler("Error edit!", "error");
      setEdit((p: INEdit | undefined) => ({
        ...p,
        errors: res?.response,
        disabled: false,
      }));
      return;
    }

    // SUCCESS
    snackHandler("Success edit!");
    queryClient.invalidateQueries(KEY_QUERY);

    setEdit((p: INEdit | undefined) => ({
      ...p,
      open: false,
      errors: undefined,
      newData: undefined,
      disabled: false,
      defaultData: undefined,
    }));
  };

  // EDIT PAY RATE
  const handleOnEditPayRate = async (
    updatedEditPayRate: INEditPayRate | undefined
  ): Promise<any> => {
    if (!updatedEditPayRate) return;

    setEditPayRate((p: INEditPayRate | undefined) => ({
      ...p,
      errors: undefined,
      disabled: true,
    }));

    const newData = clone(updatedEditPayRate?.newData as any);

    if (Array.isArray(newData?.role)) {
      newData.role = newData.role.join(",");
    }

    // Ensure only token and pay_rate are included in the request data
    const filteredData = {
      token: updatedEditPayRate?.defaultData?.token,
      pay_rate: newData.pay_rate,
    };

    const res = await useAPI(HTTP_ROUTE_EDIT, filteredData, {
      useErrors: true,
      headers: { "Content-Type": "multipart/form-data" },
    });

    // ERRORS
    if (!res?.status) {
      snackHandler("Error edit!", "error");
      setEditPayRate((p: INEditPayRate | undefined) => ({
        ...p,
        errors: res?.response,
        disabled: false,
      }));
      return;
    }

    // SUCCESS
    snackHandler("Success edit pay rate!");
    queryClient.invalidateQueries(KEY_QUERY);

    setEditPayRate((p: INEditPayRate | undefined) => ({
      ...p,
      open: false,
      errors: undefined,
      newData: undefined,
      disabled: false,
      defaultData: undefined,
    }));
  };

  // DELETE
  const handleOnDelete = async (): Promise<any> => {
    const res = await useAPI(HTTP_ROUTE_DELETE, { token: destroy?.token });
    snackHandler("Success delete!");
    queryClient.setQueryData(KEY_QUERY, res.response);
    setRows(res);
    setDestroy((p: INDestroy | undefined) => ({ ...p, token: undefined }));
  };

  // GET FEEDBACK
  const handleGetFeedback = async (
    token: string,
    name: string
  ): Promise<any> => {
    setFeedbacks((p) => ({ ...p, name, open: true }));

    const oldTutors = queryClient.getQueryData(KEY_QUERY);

    const newTutors = clone(oldTutors ?? tutors);
    const index = newTutors.findIndex((e: any) => e.token === token);
    let rowFeedbacks: INDataFeedback[] = newTutors?.[index]?.feedbacks ?? [];

    if (!Boolean(rowFeedbacks?.length)) {
      rowFeedbacks = await useAPI(HTTP_ROUTE_FEEDBACK, { token });
    }

    if (!rowFeedbacks?.length) {
      setFeedbacks({ name: "", open: false, data: [] });
      snackHandler("No feedback recorded!", "error");
      return;
    }

    newTutors[index].feedbacks = rowFeedbacks;

    queryClient.setQueryData(KEY_QUERY, newTutors);
    setRows(newTutors);
    setFeedbacks((p) => ({ ...p, data: rowFeedbacks }));
  };

  // GET AVAILABILITIES
  const handleGetAvailabilities = async (token: string, name: string) => {
    const dataAvai = {
      name,
      availabilities: [],
      token,
      keyQuery: [...KEY_QUERY, token],
    };

    refDataAvailabilities?.current?.open(dataAvai);

    let res: any = queryClient.getQueryData([...KEY_QUERY, token]);

    if (!res) {
      res = await useAPI(HTTP_ROUTE_AVAILABILITIES_SHOW, { token });
    }

    if (!Boolean(res)) {
      snackHandler("No Availabilities!", "error");
      return;
    }

    queryClient.setQueryData([...KEY_QUERY, token], res);

    refDataAvailabilities?.current?.open({
      ...dataAvai,
      availabilities: res?.rows?.array?.secondaStringa,
    });
  };

  // HIDDEN
  const handleHidden = async (token: string, hidden: number) => {
    await useAPI(HTTP_ROUTE_HIDDEN, { token, hidden });

    const newRows = [...rows];
    const index = newRows.findIndex(
      (e: GridValidRowModel | undefined) => e?.token === token
    );
    newRows[index] = { ...rows[index], hidden };

    queryClient.setQueryData(KEY_QUERY, newRows);
    setRows(newRows);
  };

  const handleGetAccessEvents = async (params: any) => {
    setAccessEventsData({
      token: params.token,
      email: params.email,
      name: params.name,
      open: true,
    });

    let startDate: string | null = null;
    let endDate: string | null = null;

    if (params?.startDate) {
      startDate = format(params?.startDate, "yyyy-MM-dd HH:mm:ss");
      endDate = format(params?.endDate, "yyyy-MM-dd HH:mm:ss");
    }

    const KEY_QUERY_DATES = [...KEY_QUERY, startDate, endDate];
    let res: any = queryClient.getQueryData(KEY_QUERY_DATES);

    if (!res) {
      res = await useAPI(HTTP_ROUTE_ACCESS, {
        email: params?.email,
        token: params?.token,
        startDate,
        endDate,
      });

      queryClient.setQueryData([...KEY_QUERY, params?.token], res);
    }

    if (!res?.length) {
      setAccessEventsData((p: any) => ({ ...p, rows: undefined, open: false }));
      snackHandler("No Access Events!", "error");
      return;
    }

    setAccessEventsData((p: any) => ({ ...p, rows: res }));
  };

  //activate invoice
  const handleActivateInvoiceSubmission = async (id: string) => {
    const res = await useAPI(HTTP_ROUTE_ACTIVATE_INVOICE_SUBMISSION + id);

    if (res?.status == false) {
      snackHandler(res?.message ?? "Something went wrong", "error");
      return;
    }

    snackHandler("Invoice submission activated successfully", "success");
  };

  const handleImpersonate = async (token: string) => {
    const res = await useAPI("/v1/impersonate/tutor", { token });

    if (res?.status == false) {
      snackHandler(res?.message ?? "Failed authorized!", "error");
      return;
    }

    window.open(
      `${VITE_ROUTE_ACADEMY_TUTOR}/impersonate/${res.data}`,
      "_blank"
    );
  };

  const handleDownload = async () => {
    setIsDisableDownload(true);
    const res = await useAPI(HTTP_ROUTE_AVAILABILITIES_DOWNLOAD, {
      filters: filtersForAPI,
    });

    setIsDisableDownload(false);
    useExportCSV(res, `English tutors ${filterData?.period_range || ""}`);
  };

  const handleContractDocDownload = async (
    token: string,
    contractFileName: string
  ) => {
    try {
      const response = await useAPI(
        `v1/general/tutors/download-contract`,
        { tutor_token: token },
        { type: "post" }
      );
      const url = window.URL.createObjectURL(
        new Blob([response], { type: "application/pdf" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", contractFileName);
      document.body.appendChild(link);
      link.click();

      if (link.parentNode) {
        link.parentNode.removeChild(link);
      } else {
        console.warn("Link's parentNode is null");
      }
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      minWidth: 180,
      flex: 0.7,
      headerName: "Name",
      valueGetter: ({ row }) =>
        row?.first_name && row?.last_name
          ? `${row?.first_name} ${row?.last_name}`
          : "-",
      renderCell: ({ row }) => {
        if (!row?.first_name && !row?.last_name) return <>-</>;

        return (
          <Tooltip
            placement="bottom-start"
            arrow
            title={
              <Box className="text-base">{`${row?.first_name} ${row?.last_name}`}</Box>
            }
          >
            <Box className="truncate">{`${row?.first_name} ${row?.last_name}`}</Box>
          </Tooltip>
        );
      },
    },
    {
      field: "email",
    },
    {
      field: "profile_pic",
      headerName: "",
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Avatar
          src={PUBLIC_STORAGE + "/public/storage/" + (row?.profile_pic || "")}
        >
          {row?.first_name?.charAt(0)}
        </Avatar>
      ),
    },
    {
      field: "status",
      minWidth: 140,
      flex: 0.5,
      headerName: "Status",
      valueGetter: ({ row }) => row?.status || "-",
    },
    {
      field: "role",
      minWidth: 180,
      flex: 0.5,
      headerName: "Type",
      renderCell: ({ row }) => (
        <MyTooltip
          title={
            <Box className="w-full flex flex-col gap-1">
              {row?.role?.split(",")?.map((e: string, i: number) => (
                <Box key={e + i}>{e}</Box>
              ))}
            </Box>
          }
        >
          {row?.role?.split(",")?.join(", ")}
        </MyTooltip>
      ),
    },
    {
      field: "pay_rate",
      headerName: "Pay rate",
      minWidth: 90,
      flex: 0.3,
      valueGetter: ({ row }) => row?.pay_rate || "-",
    },
    {
      field: "availability",
      headerName: "Availability",
      minWidth: 110,
      flex: 0.3,
      renderCell: ({ row }) => row?.availability || "-",
    },
    {
      field: "average_rating",
      headerName: "Rating",
      minWidth: 80,
      flex: 0.3,
      valueGetter: ({ row }) =>
        row?.recap_for_payments?.average_rating || row?.rating || "-",
      //   getTutorRatings(row),
      // renderCell: ({ row }) => <TutorRatings tutorId={row?.id} />,
    },
    {
      field: "report",
      headerName: "Report",
      minWidth: 100,
      flex: 0.3,
      valueGetter: ({ row }) => row?.average_event_feedback || "-",
    },
    {
      field: "hours_worked",
      headerName: "H. worked",
      minWidth: 100,
      flex: 0.3,
      valueGetter: ({ row }) => row?.total_hourly_worked,
    },
    {
      field: "delay",
      headerName: "Delays",
      minWidth: 80,
      flex: 0.3,
      valueGetter: ({ row }) => row?.total_delay,
    },
    {
      field: "refused_class",
      headerName: "Substitutions",
      minWidth: 130,
      flex: 0.3,
      valueGetter: ({ row }) => row?.substitution,
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      valueGetter: ({ row }) => (!Boolean(Number(row?.hidden)) ? "on" : "off"),
      renderCell: ({ row }) => (
        <Box
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            background: theme.palette.background.paper,
            borderRadius: "8px",
          }}
          className="flex flex-nowrap"
        >
          <Dropdown
            text={<MoreVert />}
            content={
              <MenuList>
                <MenuItem
                  color="primary"
                  title="Copy email"
                  onClick={() => copyToClipboard(row?.email || "")}
                >
                  <ListItemText>Copy email</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="WhatsApp"
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send?phone=" + row?.phone,
                      "_blank"
                    )
                  }
                >
                  <ListItemText>WhatsApp</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Copy registration link"
                  onClick={() => {
                    copyToClipboard(
                      VITE_ROUTE_ACADEMY_TUTOR + "/register/" + row.token
                    );
                  }}
                >
                  <ListItemText>Copy registration link</ListItemText>
                </MenuItem>

                {Boolean(row?.speed_test_pic) && (
                  <MenuItem
                    color="primary"
                    title="Speed test pic"
                    onClick={() =>
                      useDownloadImg(
                        PUBLIC_STORAGE +
                        "/public/storage/" +
                        (row?.speed_test_pic || ""),
                        `Speed test ${row?.fullName}`
                      )
                    }
                  >
                    <ListItemText>Speed test pic</ListItemText>
                  </MenuItem>
                )}

                <MenuItem
                  color="primary"
                  title="Availabilities"
                  onClick={() =>
                    handleGetAvailabilities(
                      row?.token,
                      `${row?.first_name} ${row?.last_name}`
                    )
                  }
                >
                  <ListItemText>Availabilities</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Feedbacks"
                  onClick={() =>
                    handleGetFeedback(
                      row?.token,
                      `${row?.first_name} ${row?.last_name}`
                    )
                  }
                >
                  <ListItemText>Feedbacks</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Check tutor setup"
                  onClick={() => refCheckTutorSetup?.current?.open(row)}
                >
                  <ListItemText>Check tutor setup</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Edit pay rate"
                  onClick={() =>
                    setEditPayRate((p: INEdit | undefined) => ({
                      ...p,
                      open: true,
                      newData: undefined,
                      defaultData: row,
                    }))
                  }
                >
                  <ListItemText>Pay rate</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Download contract"
                  onClick={(e) => {
                    const contractFileName: string = row["contract_docs"];
                    if (contractFileName == null || contractFileName == "") {
                      return;
                    } else {
                      handleContractDocDownload(row["token"], contractFileName);
                    }
                  }}
                >
                  <ListItemText>Download contract</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Comments"
                  onClick={() =>
                    setComments({ token: row.token, comments: row.comments })
                  }
                >
                  <ListItemText>Comments</ListItemText>
                </MenuItem>

                <MenuItem
                  title={
                    (Boolean(row.hidden) ? "Activate" : "Unactivate") +
                    " profile"
                  }
                  color="primary"
                  onClick={() =>
                    handleHidden(row?.token, Number(!Boolean(row.hidden)))
                  }
                >
                  <ListItemText>
                    {Boolean(row.hidden) ? "Activate" : "Deactivate"} profile
                  </ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Payment request"
                  onClick={() => refPaymentRequestDialog?.current?.open(row)}
                >
                  <ListItemText>Payment request</ListItemText>
                </MenuItem>
                <MenuItem
                  color="primary"
                  title="Payment request"
                  onClick={() => handleActivateInvoiceSubmission(row?.id)}
                >
                  <ListItemText>Activate Invoice submission</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Impersonate"
                  onClick={() => handleImpersonate(row?.token)}
                >
                  <ListItemText>Impersonate</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Edit tutor"
                  onClick={() =>
                    setEdit((p: INEdit | undefined) => ({
                      ...p,
                      open: true,
                      newData: undefined,
                      defaultData: row,
                    }))
                  }
                >
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
              </MenuList>
            }
            buttonProps={{ className: "!border-none" }}
          />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setFilters((p: any) => {
      p.set("page", page);
      return p;
    });
  }, []);

  return (
    <BasePage title="English tutors">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={tutors || []}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        components={{
          Toolbar,
          Pagination: ({ }) => (
            <CustomPagination
              hideTotal
              page={parseInt(page.toString()) - 1}
              count={dataAPI?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        componentsProps={{
          toolbar: {
            page,
            key_query,
            setFilters,
            periodRanges,
            handleDownload,
            isDisableDownload,
            filters: filterData,
            handleCreate: () => setOpenCreate(true),
          },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <Create {...{ rows, setRows, openCreate, setOpenCreate }} />

      {Boolean(edit?.open) && <Edit {...{ edit, setEdit, handleOnEdit }} />}

      {Boolean(editPayRate?.open) && (
        <EditPayRate
          {...{ editPayRate, setEditPayRate, handleOnEditPayRate }}
        />
      )}

      <AccessEvents
        getAPIAccessEvents={handleGetAccessEvents}
        {...{ accessEventsData, setAccessEventsData }}
      />

      <DataAvailabilities
        ref={refDataAvailabilities}
        keyQuery={key_query}
      />

      <DialogTutors
        data={feedbacks?.data ?? []}
        name={feedbacks?.name ?? ""}
        open={Boolean(feedbacks?.open)}
        onClose={() => setFeedbacks(DEFAULT_FEEDBACKS)}
      />

      <CheckTutorSetup ref={refCheckTutorSetup} />

      <PaymentRequestDialog ref={refPaymentRequestDialog} />

      <ConfirmDialog
        open={Boolean(destroy?.openConfirm)}
        color="error"
        onClose={() =>
          setDestroy((p: INDestroy | undefined) => ({
            ...p,
            openConfirm: false,
          }))
        }
        clickYes={handleOnDelete}
      >
        Do you want to delete this Tutor?
      </ConfirmDialog>

      {Boolean(comments?.token) && (
        <Comments
          open={Boolean(comments?.token)}
          data={comments as INComments}
          onClose={() => setComments(undefined)}
          onAgree={{ path: "/general/tutors/comments/add" }}
          setData={setComments as Dispatch<SetStateAction<INComments>>}
          onDisagree={{ path: "/general/tutors/comments/delete" }}
          keyQueryInvalidate={key_query}
        />
      )}
    </BasePage>
  );
};

export default Tutors;
