import { createRef, useEffect, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { IPlayerRef } from "@/pages/Exercises/ExercisesV2/Player";
import { queryClient } from "@/main";
import { useMutation } from "@tanstack/react-query";
import { ExerciseModal } from "./ExerciseModal";
import { EduModalIFrame } from "@/components/_Common/Modal/ModalStyles";
import { getTableStyling } from "@/providers/Colors";
import { IWatchOptionsRef } from "@/pages/Exercises/ExercisesV2/WatchOptions";
import { useExerciseContext } from "@/pages/Exercises/ExercisesV3/state/ExerciseContext";
import { ExerciseTableColumns } from "./Columns";
import { transformExercisePayload } from "../exerciseV3.utils";
import {
  DialogType,
  PotExercise,
  IeltsExercise,
  IForwardRef as ICreateRef,
} from "../exercisesV3.types";
import WatchImg, { IWatchImgRef } from "@/pages/Exercises/ExercisesV2/WatchImg";
import VersionExercises, {
  IVersionExercisesRef,
} from "@/pages/Exercises/ExercisesV2/VersionExercises";
import ViewPdfExerciseDialog, {
  IViewExerciseForwardRef,
} from "@/pages/Exercises/ExercisesV1/ViewPdfExerciseDialog";
import client from "@/api/client";
import addIcon from "@/assets/icons/add.svg";
import useScreen from "@/hooks/useScreen";
import CustomPagination from "@/components/_Common/CustomPagination";
import AddExerciseContent from "./AddExerciseContent";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import ConfirmationDialogIelts from "@/components/ConfirmationDialog";

export interface IDefaultOpenEditDialog {
  open: boolean;
  type?: "ielts-old" | "pot-new";
  payload?: IeltsExercise | PotExercise;
}

export function ExerciseTable() {
  const theme = useTheme();
  const { perPage } = useScreen();
  const { snackHandler } = useAuth();
  const { queryResult, paramState, toolBarEditState, queryKey } =
    useExerciseContext();

  const playerRef = createRef<IPlayerRef>();
  const watchImgRef = createRef<IWatchImgRef>();
  const dialogCreateRef = createRef<ICreateRef>();
  const watchOptionsRef = createRef<IWatchOptionsRef>();
  const versionExercisesRef = createRef<IVersionExercisesRef>();
  const viewPdfExerciseDialog = createRef<IViewExerciseForwardRef>();

  const [deleteRow, setDeleteRow] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [dialogFile, setDialogFile] = useState<any>();
  const [ieltsExercise, setIeltsExercise] = useState<IeltsExercise>();
  const [selectedEvents, setSelectedEvents] = useState<Array<number>>([]);
  const [openEditDialog, setOpenEditDialog] = useState<IDefaultOpenEditDialog>({
    open: false,
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const deleteNewExerciseMutation = useMutation({
    mutationFn: (id: number) =>
      client.exercises.deleteNewExercise(id, { type: "delete" }),
  });

  const deleteOldExerciseMutation = useMutation({
    mutationFn: (token: string) =>
      client.exercises.deleteOldExercise({ token }),
  });

  const handlePageChange = (_: any, newPage: number) => {
    setPageNumber(newPage + 1);
    paramState.setQueryParams((prevQueryParams) => ({
      ...prevQueryParams,
      filters: { ...prevQueryParams.filters, page: newPage + 1 },
      perPage: perPage,
    }));
  };

  const isRowSelectable = (params) => params.exercise_type === "pot";

  const handleSelect = (ids: Array<number>) => {
    setSelectedEvents(ids);
  };

  const handleOpenEdit = (row: any) => {
    const newRow = transformExercisePayload(row);
    dialogCreateRef.current?.open({ dataEdit: newRow });
  };

  const handleDeleteRow = async () => {
    const id = deleteRow;
    setDeleteRow(null);

    if (id) {
      deleteNewExerciseMutation.mutate(id);
    }
    snackHandler(
      deleteNewExerciseMutation.data.message,
      !deleteNewExerciseMutation.isError ? "error" : "success"
    );

    queryClient.invalidateQueries(queryKey);
  };

  const handleDelete = async (token: string | undefined) => {
    if (token) {
      deleteOldExerciseMutation.mutate(token);
      if (deleteOldExerciseMutation?.isError) {
        snackHandler("Failed delete exercise!", "error");
        return;
      }
      snackHandler("Success delete exercise");
      queryClient.invalidateQueries(queryKey);
    }
  };

  useEffect(() => {
    if (perPage != 0) {
      paramState.setQueryParams((prevQueryParams) => ({
        ...prevQueryParams,
        perPage: perPage,
      }));
    }
  }, [perPage]);

  return (
    <>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={queryResult?.data?.data || []}
        columns={ExerciseTableColumns({
          setDeleteRow,
          setDialogFile,
          handleOpenEdit,
          setIeltsExercise,
          setOpenEditDialog,
          setOpenDeleteDialog,
        })}
        loading={queryResult?.isLoading}
        pageSize={perPage}
        components={{
          Pagination: () => (
            <CustomPagination
              page={Number(pageNumber) - 1}
              count={queryResult?.data?.total || 0}
              rowsPerPage={perPage}
              onPageChange={handlePageChange}
            />
          ),
        }}
        selectionModel={selectedEvents}
        isRowSelectable={isRowSelectable}
        checkboxSelection={toolBarEditState.isEditing}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        onSelectionModelChange={(ids) => handleSelect(ids as Array<number>)}
      />

      <ConfirmationDialogIelts
        open={Boolean(openDeleteDialog && ieltsExercise)}
        title="Delete exercise"
        onClose={() => {
          setIeltsExercise(undefined);
          setOpenDeleteDialog(false);
        }}
        onConfirm={async () => {
          handleDelete(ieltsExercise?.token);
          setIeltsExercise(undefined);
          setOpenDeleteDialog(false);
        }}
      >
        Do you want to delete this exercise "{ieltsExercise?.name}" ?
      </ConfirmationDialogIelts>

      <ExerciseModal
        open={openEditDialog.open}
        onClose={() => setOpenEditDialog({ open: false })}
        title={
          openEditDialog.type === "ielts-old"
            ? "Edit Ielts Exercise"
            : "Edit Pot Exercise"
        }
        icon={
          <img
            src={addIcon}
            alt="Add icon"
          />
        }
      >
        <AddExerciseContent
          isEditing
          type={openEditDialog.type as DialogType}
          playerRef={playerRef}
          watchImgRef={watchImgRef}
          exercisePayload={openEditDialog.payload}
        />
      </ExerciseModal>

      {Boolean(dialogFile) && (
        <EduModalIFrame
          link={(dialogFile?.files?.media as string)?.replace(
            "http://localhost/Edusogno/api",
            "https://edusogno.com/api"
          )}
          title={<>Media - {dialogFile?.name}</>}
          onClose={() => setDialogFile(undefined)}
        />
      )}

      <WatchImg ref={watchImgRef} />

      <VersionExercises
        ref={versionExercisesRef}
        watchOptionsRef={watchOptionsRef}
      />

      <ConfirmationDialog
        open={typeof deleteRow === "number" && deleteRow !== null}
        onClose={() => setDeleteRow(null)}
        onConfirm={handleDeleteRow}
      >
        Do you want to{" "}
        <span style={{ color: theme.palette.primary.main }}>delete</span> this
        exercise?
      </ConfirmationDialog>

      <ViewPdfExerciseDialog ref={viewPdfExerciseDialog} />
    </>
  );
}
