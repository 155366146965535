import {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { it } from "date-fns/locale";
import { format } from "date-fns";
import useAuth from '@/hooks/useAuth';
import { LoadingButton } from "@mui/lab";
import { clone, fUpper } from "@/components/useHelpers";
import { useMutateOrder, usePaymentOptions } from "@/api/orders";
import { OrdersData, UserOrders, UserTypeof } from "@/interfaces";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  FormGroup,
  TextField,
  InputLabel,
  FormControlLabel,
} from "@mui/material";
import editIcon from "@/assets/icons/edit.svg";
import saveIcon from "@/assets/icons/save.svg";
import MyTooltip from "@/components/MyTooltip";
import classNames from "classnames";
import useProductApi from "@/api/useProductApi";
import ProductSelect from "@/components/ProductSelect";
import EnabledPaymentsIcon from "./EnabledPaymentsIcon";

const whiteListKeys = [
  "left_time",
  "product_tk",
  "start_month",
  "enabled_payments",
  "default_discount_amount",
];

export interface IRef {
  open: (orders: Array<OrdersData>, user?: UserOrders) => void;
}

type IProps = {};

export default forwardRef<IRef, IProps>((_, ref) => {
  const { copyToClipboard } = useAuth();
  const { mutate: edit, isLoading } = useMutateOrder();

  const { data: dataProducts, isLoading: isLoadingProducts } =
    useProductApi().callGetProducts(() => { }, true);

  const [user, setUser] = useState<UserOrders>();
  const [form, setForm] = useState({} as OrdersData);
  const [order, setOrder] = useState<OrdersData>();
  const [orders, setOrders] = useState<Array<OrdersData>>();
  const [addDays, setAddDays] = useState<number | null>(null);

  const { availablePaymentTypes: providers } = usePaymentOptions();

  const isTutoring =
    user?.tutoring ||
    order?.user?.is_tutoring ||
    order?.typeof === UserTypeof.Tutoring;

  const leftTime = form?.left_time ? new Date(form?.left_time) : new Date();

  const isExpired = leftTime < new Date();

  const fullName =
    (user?.first_name ?? order?.name ?? order?.user?.first_name) +
    " " +
    (user?.last_name ?? order?.lname ?? order?.user?.last_name);

  useImperativeHandle(ref, () => ({
    open: (orders, user) => {
      orders = clone(orders);
      setUser(user);
      setOrder(orders.find((o) => o.status !== "completed"));
      setOrders(orders);
    },
  }));

  const products = useMemo(
    () =>
      (dataProducts?.products || [])?.filter(
        (product: any) =>
          Boolean(Number(product.tutoring)) === Boolean(Number(isTutoring))
      ),
    [dataProducts?.products, isTutoring]
  );

  const isProductHidden =
    form?.product_tk &&
    !products?.find((p: any) => p.token == form?.product_tk);

  const startMonths = useMemo(
    () =>
      Array.from({ length: 12 }, (_, i) => {
        const year = new Date().getFullYear();
        const currentMonthIndex = new Date().getMonth() + 1;
        const offset = currentMonthIndex + i;
        const date = new Date(year, offset, 1);

        return {
          label: format(date, "MMMM yyyy"),
          value: fUpper(format(date, "MMMM yyyy", { locale: it })),
        };
      }),
    []
  );

  const handleClose = () => {
    setUser(undefined);
    setForm({} as OrdersData);
    setOrder(undefined);
    setOrders(undefined);
    setAddDays(null);
  };

  const handleEnabledPayments = (payment_type, installments, e) => {
    const checked = e.target.checked;

    if (checked) {
      setForm((p) => ({
        ...p,
        [e.target.name]: [
          ...(p[e.target.name] ?? []),
          { payment_type, installments },
        ],
      }));
    } else {
      setForm((p) => ({
        ...p,
        [e.target.name]: p[e.target.name].filter((item) => {
          return (
            item.payment_type !== payment_type ||
            item.installments !== installments
          );
        }),
      }));
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    if (name === "default_discount_amount") {
      setForm({ ...form, [name]: parseInt(value) * 100 });
      return;
    }

    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (!order) {
      setForm({} as OrdersData);
      return;
    }

    let temp = {} as OrdersData;

    Object.keys(order).forEach((key) => {
      if (whiteListKeys.includes(key)) {
        temp[key] = order[key];
      }
    });

    setForm(temp);
  }, [order]);

  useEffect(() => {
    if (!order?.left_time) return;

    const newDate = new Date(
      addDays === null ? order.left_time : new Date().setHours(20, 0, 0, 0)
    );

    newDate.setDate(newDate.getDate() + (addDays ?? 0));

    setForm((p) => ({
      ...p,
      left_time: format(newDate, "yyyy-MM-dd HH:mm:ss"),
    }));
  }, [addDays, order]);

  return (
    <EduModal
      fullWidth
      open={Boolean(orders?.length)}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>
        <img
          src={editIcon}
          className="icon"
        />
        Edit order{orders && orders?.length > 1 ? "s" : ""} - {fullName}
      </EduModalTitle>

      <EduModalBody className="flex flex-col p-5 pt-6 gap-10">
        {orders && orders?.length > 1 && (
          <TextField
            select
            fullWidth
            label="Orders user"
            value={order?.token || ""}
            onChange={(e) =>
              setOrder(
                orders?.find((o) => o?.token == e.target.value) ?? orders[0]
              )
            }
          >
            {orders.map((o) => (
              <MenuItem
                key={o.token + o.id}
                value={o.token}
                disabled={o?.status == "completed"}
              >
                {o?.token} - {fUpper(o?.typeof || "")} - {fUpper(o?.status)}
              </MenuItem>
            ))}
          </TextField>
        )}

        {order ? (
          <>
            <Box className="flex flex-col gap-5">
              {isProductHidden && (
                <Box>
                  If the product is empty, it means the product is hidden.
                </Box>
              )}

              <ProductSelect
                value={isProductHidden ? "" : form?.product_tk}
                products={products}
                onChange={handleChange}
                isLoading={isLoadingProducts}
              />

              <FormGroup>
                <InputLabel
                  required
                  id="enabled-providers"
                >
                  Enabled providers
                </InputLabel>

                <Box className="!grid grid-cols-2">
                  {providers?.map((provider, index) => (
                    <FormControlLabel
                      key={provider + "-" + index}
                      label={
                        <Box className="flex gap-2 items-center">
                          <EnabledPaymentsIcon providers={[provider]} />
                          {provider.display_name}
                        </Box>
                      }
                      control={
                        <Checkbox
                          name="enabled_payments"
                          color="primary"
                          value={provider.payment_type.toLowerCase()}
                          checked={
                            form?.enabled_payments?.filter(
                              (item) =>
                                item.payment_type === provider.payment_type &&
                                item.installments == provider.installments
                            ).length > 0 || false
                          }
                          onChange={(e) =>
                            handleEnabledPayments(
                              provider.payment_type,
                              provider.installments,
                              e
                            )
                          }
                        />
                      }
                    />
                  ))}
                </Box>
              </FormGroup>

              {order?.typeof === UserTypeof.Upselling && (
                <TextField
                  select
                  fullWidth
                  id="start-month-select"
                  name="start_month"
                  color="primary"
                  label="Start month"
                  value={form?.start_month || ""}
                  onChange={handleChange}
                >
                  {startMonths.map((m, i) => (
                    <MenuItem
                      key={m.value + "-" + i}
                      value={m.value}
                    >
                      {m.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                type="number"
                name="default_discount_amount"
                label="Default discount amount"
                value={
                  form?.default_discount_amount
                    ? form?.default_discount_amount / 100
                    : 0
                }
                variant="outlined"
                onChange={handleChange}
              />

              <Box className="w-full flex items-stretch justify-between gap-4">
                <Button
                  color="primary"
                  variant="outlined"
                  className="text-lg"
                  onClick={() =>
                    setAddDays((p: null | number) =>
                      p === null ? -1 : p === 0 ? null : p - 1
                    )
                  }
                >
                  -
                </Button>

                <Box
                  className={classNames(
                    "text-green-600 grow flex justify-center items-center",
                    { "!text-red-500": isExpired }
                  )}
                >
                  <MyTooltip
                    title={"Add days: " + addDays}
                    placement="bottom"
                    className="flex justify-center items-center"
                  >
                    {addDays === null && "Default:"}{" "}
                    {leftTime && format(leftTime, "EEEE, dd MMMM HH:mm")}
                  </MyTooltip>
                </Box>

                <Button
                  color="primary"
                  variant="outlined"
                  className="text-lg"
                  onClick={() =>
                    setAddDays((p) =>
                      p === null ? 0 : p === -1 ? null : p + 1
                    )
                  }
                >
                  +
                </Button>
              </Box>
            </Box>

            <Box className="flex flex-row-reverse">
              <LoadingButton
                color="primary"
                variant="outlined"
                loading={isLoading}
                onClick={() => {
                  if (!order?.token) return;
                  edit({ token: order.token, data: form });
                }}
                disabled={!order?.token}
                startIcon={<img src={saveIcon} />}
              >
                Save
              </LoadingButton>
            </Box>
          </>
        ) : (
          Boolean(orders?.[0]) && (
            <Button
              fullWidth
              onClick={() => copyToClipboard(orders?.[0]?.token ?? "")}
            >
              {orders?.[0]?.token} - {fUpper(orders?.[0]?.typeof || "")} -{" "}
              {fUpper(orders?.[0]?.status ?? "")}
            </Button>
          )
        )}
      </EduModalBody>
    </EduModal>
  );
});
